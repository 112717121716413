import { ArrowBack, Check } from '@mui/icons-material';
import { Autocomplete, Box, Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SCHEDULES, VEHICLE_NOS } from "../../constants";
import { fsdb } from '../../firebase-config';

export default function CreateMapping() {
  const navigate = useNavigate();
  const [vehicleRegNo, setVehicleRegNo] = useState(null);
  const [scheduleId, setScheduleId] = useState(null);
  const [scheduleDate, setScheduleDate] = useState(null)

  const handleSubmit = () => {
    const setSchedule = async () => {
      const scheduleCollectionRef = collection(fsdb, "schedules");

      const dateObject = new Date(scheduleDate);

      // Extract the day, month, and year from the Date object
      const day = dateObject.getDate().toString().padStart(2, '0');
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
      const year = dateObject.getFullYear();

      // Format the date in "ddmmyyyy" format
      const formattedDate = `${day}/${month}/${year}`

      const newDoc = {
        vehicleRegNo,
        scheduleId,
        scheduleDate: formattedDate,
        assigned: true,
        createdAt: serverTimestamp(),
      };
      console.log(newDoc);
      // Write the new document to Firestore

      const timeout = 5000; // 5 seconds
      const promise = addDoc(scheduleCollectionRef, newDoc);
      try {
        await Promise.race([
          promise,
          new Promise((resolve, reject) =>
            setTimeout(() => reject(new Error('Timeout')), timeout)
          )
        ]);
        alert("New entry added successfully!");
        navigate('/');

      }
      catch (error) {
        alert(error)
      }
    }
    if (vehicleRegNo && scheduleId && scheduleDate)
      setSchedule();
    else
      alert("Fill out all fields")
  }

  return <Box sx={{ maxWidth: 800, mx: "auto", my: 16, width: "90%" }}>
    <Box mb={2}>
      <Link to="/">
        <IconButton>
          <ArrowBack />
        </IconButton>
      </Link>
    </Box>
    <Box mb={2}>
      <Typography variant='h3' fontWeight={500}>
        Create Schedule to Vehicle Mapping
      </Typography>
    </Box>

    <Stack direction="row" spacing={2}>
      <DatePicker
        sx={{ flex: 1 }}
        label="Schedule date"
        value={scheduleDate}
        onChange={(newValue) => setScheduleDate(newValue)}
      />
      <Autocomplete
        disablePortal
        fullWidth
        options={SCHEDULES}
        value={scheduleId}
        onChange={(event, newValue) => {
          setScheduleId(newValue);
        }}
        sx={{ bgcolor: "white.main", flex: 1 }}
        renderInput={(params) => <TextField {...params} label="Schedule" />}
      />
      <Autocomplete
        disablePortal
        fullWidth
        options={VEHICLE_NOS}
        value={vehicleRegNo}
        onChange={(event, newValue) => {
          setVehicleRegNo(newValue);
        }}
        sx={{ bgcolor: "white.main", flex: 1 }}
        renderInput={(params) => <TextField {...params} label="Vehicle" />}
      />
    </Stack>
    <Box mt={4}>
      <Button onClick={handleSubmit} variant="contained" startIcon={<Check />}>Confirm schedule</Button>
    </Box>
  </Box>
}