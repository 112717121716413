// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCasq5e6jeZcr_kzZTKVxBr2F072CxOUxM",
  authDomain: "flexibus-379607.firebaseapp.com",
  databaseURL: "https://flexibus-379607-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "flexibus-379607",
  storageBucket: "flexibus-379607.appspot.com",
  messagingSenderId: "705202992973",
  appId: "1:705202992973:web:18180a0fe0234487fae904",
  measurementId: "G-R8GPNZ28C4"
};

const app = initializeApp(firebaseConfig)

const fsdb = getFirestore(app);

const rtdb = getDatabase(app);

export { fsdb, rtdb };
