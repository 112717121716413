import { ThemeProvider } from "@emotion/react";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import CreateMapping from "./pages/CreateMapping";
import LiveTracking from "./pages/LiveTracking";
import VehicleScheduleMapping from "./pages/VehicleScheduleMapping";
import theme from "./theme";

function App() {
  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <ThemeProvider theme={theme}>
      <Navbar />
      <Routes>
        <Route index path="" Component={VehicleScheduleMapping} />
        <Route path="/create-mapping" Component={CreateMapping} />
        <Route path="/live-tracking" Component={LiveTracking} />
      </Routes>
    </ThemeProvider>
  </LocalizationProvider>
}

export default App;
