import { Autocomplete, Box, TextField } from "@mui/material";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { VEHICLE_NOS } from "../../constants";
import { rtdb } from "../../firebase-config";

export default function App() {
  /* Load the map */
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDEbHkmhZZFmgR_tG9JK7Pjj3MiCza6plI',
  });

  /* Initial map config */
  const [map, setMap] = useState(null);
  const [mapPosition, setMapPosition] = useState({ lat: 15.374995, lng: 73.999092 });
  const [vehicleRegNo, setVehicleRegNo] = useState(VEHICLE_NOS[0])
  const [trackingAPIResponse, setTrackingAPIResponse] = useState(null);
  const busLocation = { lat: trackingAPIResponse?.latitude, lng: trackingAPIResponse?.longitude }
  console.log(busLocation)

  /* Set dragged position to map position */
  const handleMapDrag = () => {
    setMapPosition({
      lat: map.getCenter().lat(),
      lng: map.getCenter().lng(),
    });
  };

  useEffect(() => {
    const trackingRef = ref(rtdb, `itms/${vehicleRegNo}`);
    const unsubscribe = onValue(trackingRef, (snapshot) => {
      console.log("Bus Location Update: ");
      const json = snapshot.toJSON()
      console.log(json)
      if (json) {
        if (json.hasOwnProperty("location")) {
          setTrackingAPIResponse(json.location);
        }
      }
      else {
        alert("No data availale");
      }
    }, (error) => {
      console.error(error);
    });
    return () => unsubscribe();
  }, [vehicleRegNo])

  if (isLoaded)
    return <Box height="100vh" width="100vw">
      <GoogleMap onDragEnd={handleMapDrag} onLoad={(map) => setMap(map)} options={{
        mapTypeId: "roadmap",
        streetViewControlOptions: { position: window.google.maps.ControlPosition.TOP_RIGHT }, zoomControl: false
      }} center={mapPosition} zoom={12} mapContainerStyle={{ width: '100%', height: "100%" }}>
        {trackingAPIResponse && <Marker icon={{ url: "/images/bus.png", scaledSize: { width: 32, height: 32 } }} position={busLocation} />}
      </GoogleMap>
      <Box sx={{ position: "absolute", left: "32px", right: "32px", bottom: "32px", maxWidth: "300px" }}>
        <Autocomplete
          disablePortal
          fullWidth
          options={VEHICLE_NOS}
          value={vehicleRegNo}
          onChange={(event, newValue) => {
            setVehicleRegNo(newValue);
          }}
          sx={{ bgcolor: "white.main" }}
          renderInput={(params) => <TextField {...params} label="Vehicle" />}
        />
      </Box>
    </Box>
}