export const VEHICLE_NOS = ["GA08V4966", "GA08V5691", "GA08V5703", "GA08V5706", "GA08V5698", "GA08V5692", "GA08V5690", "GA08V5701", "GA08V5705", "GA08V5704", "GA08V5700", "GA08V5696", "GA08V5697", "GA08V5693", "GA08V5702", "GA08V5409", "GA08V5410", "GA08V5699", "GA08V5408", "GA08V5406", "GA08V5407", "GA08V4967", "GA08V4965", "GA08V5115", "GA08V4982", "GA08V4976", "GA08V5071", "GA08V4975", "GA08V4980", "GA08V4985", "GA08V4979", "GA08V4984", "GA08V4981", "GA08V4983", "GA08V5114", "GA08V4968", "GA08V4986", "GA08V5073", "GA08V5072", "GA08V4991", "GA08V4978", "GA08V4988", "GA08V4990", "GA08V4973", "GA08V5070", "GA08V4987", "GA08V4989", "GA08V4974", "GA08V4972", "GA08V4977"];
export const SCHEDULES = [
  '34M', 'M34', '75M',  'M75',  '40M', 'M40', '74M',  'M74',
  '81M', 'M81', '35M',  'M35',  '37M', 'M37', '43M',  'M43',
  '55M', 'M55', '44M',  'M44',  '38M', 'M38', '45M',  'M45',
  '39M', 'M39', '46M',  'M46',  '41M', 'M41', '77M',  'M77',
  '42M', 'M42', '32M',  'M32',  '76M', 'M76', '47M',  'M47',
  '48M', 'M48', '49M',  'M49',  '51M', 'M51', '52M',  'M52',
  '33M', 'M33', '82M',  'M82',  '50M', 'M50', '79M',  'M79',
  '78M', 'M78', '80M',  'M80',  '83M', 'M83', '36M',  'M36',
  '37P', 'P37', '38P',  'P38',  '39P', 'P39', '39V',  'V39',
  '53M', 'M53', '54M',  'M54',  '41V', 'V41', '84M',  'M84',
  '36P', 'P36', '40V',  'V40',  '42V', 'V42', '35P',  'P35',
  '47P', 'P47', '43V',  'V43',  '48P', 'P48', '67PV', 'PV67',
  '85M', 'M85', '66PV', 'PV66'
]