import { Add } from "@mui/icons-material";
import { Box, Button, TableBody, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { query } from "firebase/database";
import { collection, doc, limit, onSnapshot, orderBy, serverTimestamp, updateDoc, where } from "firebase/firestore";
import moment from "moment";
import * as React from 'react';
import { Link } from "react-router-dom";
import { fsdb } from "../../firebase-config";

export default function VehicleScheduleMapping() {
  const [entries, setEntries] = React.useState(null);

  React.useEffect(() => {
    const collectionRef = collection(fsdb, 'schedules');

    const today = moment().format('DD/MM/YYYY');

    const tomorrow = moment().add(1, 'day').format('DD/MM/YYYY');


    // Set up the real-time listener with the query
    const q = query(collectionRef,
      where('scheduleDate', 'in', [today, tomorrow]),
      orderBy("createdAt", "desc"),
      limit(50));    // createdAt < today
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const updatedUsers = [];
      querySnapshot.forEach((doc) => {
        // doc.data() returns the document's data as an object
        const data = { id: doc.id, ...doc.data() };
        updatedUsers.push(data);
      });

      setEntries(updatedUsers);
      console.log(updatedUsers)
    });

    // Clean up the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, []);

  // function sortAndFilterObjectByCreatedAt(obj) {
  //   const today = new Date().setHours(0, 0, 0, 0);
  //   const sortedKeys = Object.keys(obj)
  //     .filter(key => new Date(obj[key].createdAt).setHours(0, 0, 0, 0) === today)
  //     .sort((a, b) => obj[b].createdAt - obj[a].createdAt);

  //   const sortedAndFilteredObject = {};

  //   for (const key of sortedKeys) {
  //     sortedAndFilteredObject[key] = obj[key];
  //   }

  //   return sortedAndFilteredObject;
  // }


  const getFormattedDate = (dateString) => {
    // Parse the date string with Moment.js
    const date = moment(dateString, 'DD/MM/YYYY');

    // Get today's date without time
    const today = moment().startOf('day');

    // Get yesterday's date without time
    const yesterday = moment().subtract(1, 'day').startOf('day');

    // Get tomorrow's date without time
    const tomorrow = moment().add(1, 'day').startOf('day');

    if (date.isSame(today, 'day')) {
      return 'Today';
    } else if (date.isSame(yesterday, 'day')) {
      return 'Yesterday';
    } else if (date.isSame(tomorrow, 'day')) {
      return 'Tomorrow';
    } else {
      return dateString; // Not today, yesterday, or tomorrow
    }
  };

  const getFormattedDateTime = (createdAt) => {
    if (createdAt)
      return moment(createdAt.toDate()).format('MMMM D, YYYY - HH:mm:ss');
  }

  const toggleAssign = async (id) => {
    const collectionRef = doc(fsdb, "schedules", id);

    const currData = entries.filter(elem => elem.id === id)[0];
    await updateDoc(collectionRef, {
      createdAt: serverTimestamp(),
      assigned: !currData.assigned,
    })
  }

  return <Box sx={{ maxWidth: 1200, mx: "auto", my: 16, width: "90%" }}>
    <Box mb={2}>
      <Typography variant='h3' fontWeight={500}>
        Schedule to Vehicle Mapping
      </Typography>
    </Box>
    <Box width="100%">
      <Link to="/create-mapping">
        <Button variant='contained' startIcon={<Add />} sx={{ float: "right", mb: 4 }}>Add new entry</Button>
      </Link>
    </Box>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Sr. No</TableCell>
            <TableCell>Vehicle No</TableCell>
            <TableCell>Schedule Id</TableCell>
            <TableCell>Scheduled for</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Last Updated</TableCell>
          </TableRow>
        </TableHead>
        {entries ? <TableBody>
          {entries.map(({ id, vehicleRegNo, scheduleId, scheduleDate, createdAt, assigned }, idx) => (
            <TableRow
              key={idx}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ color: "#00000080" }} component="th" scope="row">
                {idx + 1}
              </TableCell>
              <TableCell sx={{ color: "#00000080" }}>{vehicleRegNo}</TableCell>
              <TableCell sx={{ color: "#00000080" }}>{scheduleId}</TableCell>
              <TableCell sx={{ color: "#00000080" }}>{getFormattedDate(scheduleDate)}</TableCell>
              <TableCell sx={{ color: "#00000080" }}>{assigned ? "Assigned" : "De-Assigned"}</TableCell>
              <TableCell sx={{ color: "#00000080" }}>{getFormattedDateTime(createdAt)}</TableCell>
              <TableCell sx={{ color: "#00000080" }}>

                <Button
                  variant="contained"
                  color={assigned ? "error" : "info"}
                  size="small"
                  onClick={() => toggleAssign(id)}
                >
                  {assigned ? "De-assign" : "Re-Assign"}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody> :
          <TableCell sx={{ color: "#00000080" }}>No data to show</TableCell>
        }
      </Table>
    </TableContainer>
  </Box>
}